@use 'sass:math';
@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Quota Group Card
/* ---------------------------------------------------------------------- */

.quota-group-card {
  display: flex;
  flex-direction: column;
  gap: math.div($space-small, 2);

  > h4 {
    margin-top: math.div($space-small, 2);
  }
}

.quota-group-card-layout {
  display: flex;
  flex-direction: row;
  gap: 2 * $space;

  > div:first-of-type {
    flex: 1;

    > hr {
      background-color: var(--color-border-subdued);
      margin-bottom: $space;
    }
  }
  > div:last-of-type {
    width: 275px;

    > p {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
}

.quota-group-card-line {
  background: var(--color-border-subdued);
  width: 1px;
}

.quota-group-card-responses {
  display: inline-flex;
}
