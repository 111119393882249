@use 'sass:math';
@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Group Madlib
/* ---------------------------------------------------------------------- */

.group-madlib {
  margin: math.div($space-small, 2) 0 $space 0;

  &.is-completed {
    margin-bottom: -$space-small;
  }

  > button {
    margin-bottom: math.div($space, 2);
  }

  > span + button {
    margin-left: math.div($space-small, 2);
  }

  > button + span {
    margin-left: math.div($space-small, 4);
  }
}

.behavior-block {
  align-items: flex-start;
  display: flex;
  gap: $space-small;
}

.button-icon {
  color: var(--color-text-faint);
  cursor: pointer;
  margin-top: math.div($space-small, 2);

  &.minus {
    &:hover {
      color: var(--color-icon-danger);
    }
  }

  &.plus {
    &:hover {
      color: var(--color-text-link);
    }
  }
}
