@use 'sass:math';
@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Modal Instant Survey Question
/* ---------------------------------------------------------------------- */

.modal-instant-survey-question {
  display: grid;
  row-gap: $space;
}

.modal-instant-survey-question-actions {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: $space-small;

  svg {
    color: var(--color-text-faint);
  }
}

.modal-instant-survey-question-text-and-type {
  display: flex;
  flex-direction: row;
  gap: $space-small;

  > div:first-of-type {
    flex: 1;
    width: 625px;
  }
  > div:last-of-type {
    flex-shrink: 0;
    width: 300px;
  }
}

.modal-instant-survey-question-select {
  display: block;
  min-width: auto;
}

.modal-instant-survey-question-randomize {
  margin: math.div($space-small, 2) 0 $space;
}

.modal-instant-survey-question-input {
  resize: none;
  width: 100%;
}

.modal-instant-survey-question-none,
.modal-instant-survey-question-other {
  display: flex;
  flex-direction: column;
  height: $button-height;
  justify-content: center;

  em {
    font-style: italic;
  }
}

.modal-instant-survey-question-options {
  border-top: 1px dashed var(--color-border-default);
  margin-top: math.div($space, 2);
  padding-left: $space + $space-small;
  padding-top: $space;
}
