@import '../../_variables.scss';

/* ---------------------------------------------------------------------- */
/*  Error Text
/* ---------------------------------------------------------------------- */

.error-text {
  align-items: center;
  color: var(--color-text-error);
  display: flex;
  gap: $space-small;

  &:before {
    background-color: var(--color-text-error);
    border-radius: 50%;
    content: '';
    display: block;
    flex-shrink: 0;
    height: $space-small;
    width: $space-small;
  }
}
