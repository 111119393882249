@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Module List
/* ---------------------------------------------------------------------- */

.module-list {
  > h4 {
    margin-top: $space * 2;
  }
  &.favorite {
    border-bottom: 1px solid var(--color-border-subdued);
    padding-bottom: $space * 2;
  }

  &.specialty {
    border-top: 1px solid var(--color-border-subdued);
    margin-top: $space * 2;
  }
}
