@use 'sass:math';
@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Answer
/* ---------------------------------------------------------------------- */

.answer {
  align-items: flex-start;
  display: flex;
  gap: $space-small;
  flex: 1;
  padding: math.div($space, 2) 0;
}

.answer-controls,
.answer-icons,
.answer-options,
.answer-properties {
  align-items: center;
  display: inline-flex;
  flex-shrink: 0;
  justify-content: center;
  min-height: $button-height; // to vertically align pieces to a button/input
}

.answer-icons,
.answer-properties {
  svg {
    color: var(--color-icon-default);
    height: $icon-size;
    width: $icon-size;
  }
}

.answer-pipe {
  background: var(--color-border-subdued);
  display: block;
  height: 20px;
  width: 1px;
}
